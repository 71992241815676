require("normalize.css/normalize.css");
require("./styles/index.scss");

document.addEventListener("DOMContentLoaded", () => {
    const mobileBurger = document.getElementsByClassName("burger")[0];
    const header = document.getElementsByTagName("header")[0];
    const body = document.getElementsByTagName("body")[0];
    const el = document.querySelectorAll("#main ul li a");

    el.forEach(input =>
        input.addEventListener("click", () => {
            header.classList.remove("menu-open");
        })
    );
    mobileBurger.onclick = () => {
        header.classList.toggle("menu-open");
    };

    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
        anchor.addEventListener("click", function(e) {
            e.preventDefault();
            const id = this.getAttribute("href").split("#")[1];
            window.location.hash = `/${id}`;

            body.setAttribute("data-target", id);
            const t = document.querySelector(this.getAttribute("href"));
            if (id !== "datenschutz" && id !== "impressum") {
                scrollIt(t ? t : 0);
            } else {
                window.scroll(0, 0);
            }
        });
    });
    window.onscroll = function() {
        stickFnc();
    };

    var sticky = header.offsetTop + 170;
    function stickFnc() {
        if (window.pageYOffset > sticky) {
            header.classList.add("sticky");
        } else {
            header.classList.remove("sticky");
        }
    }
    stickFnc();

    function switchContent() {
        const id = window.location.hash.split("#")[1];
        body.setAttribute("data-target", id);
    }
    switchContent();

    function home() {
        window.location.hash = `/`;
        window.scroll(0, 0);
        body.setAttribute("data-target", "");
        header.classList.remove("menu-open");

    }

    // Home
    document
        .querySelectorAll(".logo img")[0]
        .addEventListener("click", function(e) {
            home();
        });
    document
        .querySelectorAll(".logo div")[0]
        .addEventListener("click", function(e) {
            home();
        });
});
function scrollIt(
    destination,
    duration = 500,
    easing = "easeInOutCubic",
    callback
) {
    const easings = {
        linear(t) {
            return t;
        },
        easeInQuad(t) {
            return t * t;
        },
        easeOutQuad(t) {
            return t * (2 - t);
        },
        easeInOutQuad(t) {
            return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
        },
        easeInCubic(t) {
            return t * t * t;
        },
        easeOutCubic(t) {
            return --t * t * t + 1;
        },
        easeInOutCubic(t) {
            return t < 0.5
                ? 4 * t * t * t
                : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
        },
        easeInQuart(t) {
            return t * t * t * t;
        },
        easeOutQuart(t) {
            return 1 - --t * t * t * t;
        },
        easeInOutQuart(t) {
            return t < 0.5 ? 8 * t * t * t * t : 1 - 8 * --t * t * t * t;
        },
        easeInQuint(t) {
            return t * t * t * t * t;
        },
        easeOutQuint(t) {
            return 1 + --t * t * t * t * t;
        },
        easeInOutQuint(t) {
            return t < 0.5
                ? 16 * t * t * t * t * t
                : 1 + 16 * --t * t * t * t * t;
        }
    };

    const start = window.pageYOffset;
    const startTime =
        "now" in window.performance ? performance.now() : new Date().getTime();

    const documentHeight = Math.max(
        document.body.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.clientHeight,
        document.documentElement.scrollHeight,
        document.documentElement.offsetHeight
    );
    const windowHeight =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.getElementsByTagName("body")[0].clientHeight;
    const destinationOffset =
        typeof destination === "number" ? destination : destination.offsetTop;
    const destinationOffsetToScroll =
        Math.round(
            documentHeight - destinationOffset < windowHeight
                ? documentHeight - windowHeight
                : destinationOffset
        ) - 54;
    if ("requestAnimationFrame" in window === false) {
        window.scroll(0, destinationOffsetToScroll);
        if (callback) {
            callback();
        }
        return;
    }

    function scroll() {
        const now =
            "now" in window.performance
                ? performance.now()
                : new Date().getTime();
        const time = Math.min(1, (now - startTime) / duration);
        const timeFunction = easings[easing](time);
        window.scroll(
            0,
            Math.ceil(
                timeFunction * (destinationOffsetToScroll - start) + start
            )
        );

        if (window.pageYOffset === destinationOffsetToScroll) {
            if (callback) {
                callback();
            }
            return;
        }

        requestAnimationFrame(scroll);
    }

    scroll();
}
